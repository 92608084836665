.container {
    font-family: 'Lato',sans-serif;
    margin: 0 10px 0 10px;

    .bubble {
        padding: 15px 20px 15px 20px;
        background: #FFF;
        border: #d0d0d0;
        border-radius: 20px 20px;

        h2 {
            font-size: 1.5em;
            font-weight: bold;
            margin: 0;
        }

        h3 {
            font-size: 1.17em;
            font-weight: bold;
            margin: 0;
        }

        .extra {
            margin: 10px 0 0 0;
            font-size: 11pt;
            color: #606060;
            line-height: 22px;
        }
    }

    @media (min-width: 550px) {
        margin: 10px 0px;
        min-height: auto !important;

        .extra {
            display: block;
        }
    }

    @media (min-width: 990px) {
        .bubble {
            width: 75%;
        }
    }
}